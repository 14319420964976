const axios = window.axios
const url = '/api/v2/tariffs/leisure/'
const urlCreate = '/api/v2/tariffs/leisure-create/'




export const LeisureTariffsApiUrls = {
    // leisure tariffs
    async getTariffs(params){
        return (await axios.get(`${url}`, { params: params })).data;
    },
    async createTariff(body){
        return (await axios.post(`${urlCreate}`, body)).data;
    },
    async getTariff(id){
        return (await axios.get(`${url}${id}/`)).data;
    },
    async setTariff(id, body){
        return (await axios.put(`${url}${id}/`, body)).data;
    },
    async deleteTariff(id){
        return (await axios.delete(`${url}${id}/`)).data;
    },
}