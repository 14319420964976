<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10"
        :loading="loading"
        :options.sync="options"
        :footer-props="footerProps"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ items.map(x => x.id).indexOf(item.id) + 1 }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>

          <v-icon small color="red" class="mr-2" @click="deleteTariff(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-btn @click="openTariff" color="primary" class="ma-3">
        Добавить тариф
      </v-btn>
    </v-card>

    <!-- попап окно с выбором тарифа/редактирования тарифа -->

    <v-dialog v-model="dialog" max-width="1200">
      <v-card class="pa-5">
        <v-card-title
          class="font-weight-bold text-left"
          style="font-size: 24px; padding: 0"
          >{{
            isEditing ? "Изменение тарифов" : "Добавление тарифов"
          }}</v-card-title
        >
        <v-row v-if="!isEditing">
          <v-col cols="6">
            <v-select
              label="Категория"
              :items="catregoryList"
              item-text="name"
              item-value="id"
              v-model="newResult.category"
              :disabled="isEditing"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="periodType"
              @change="setPeriod"
              label="Период"
              :items="periodList"
              :disabled="isEditing"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col cols="6">
            <v-select
              :label="editingCategoryName"
              :items="catregoryList"
              item-text="name"
              item-value="id"
              v-model="editingCategoryName"
              :disabled="isEditing"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="periodType"
              @change="setPeriod"
              label="Период"
              :items="periodList"
              :disabled="isEditing"
            ></v-select>
          </v-col>
        </v-row>

        <!-- новая формочка по дням/месяцам -->
        <div>
          <v-row>
            <v-col cols="6" md="4">
              <v-select
                v-model="newResult.enum"
                multiple
                :items="selectItems"
                :label="periodLabel"
                :disabled="periodLabel == 'Выберите период' && !isEditing"
                @change="handleChange"
              ></v-select>
            </v-col>

            <v-col cols="6" md="3">
              <v-text-field
                type="number"
                :disabled="isEditing"
                v-model="newResult.hour_period"
                placeholder="Период активности"
              ></v-text-field>
            </v-col>

            <v-expansion-panels>
              <v-expansion-panel
                v-for="(item_1, key) in newResult.time_list"
                :key="key"
              >
                <v-expansion-panel-header
                  v-text="convertedEnum[key] || 'Заполните'"
                >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row v-for="(item, index_2) in item_1" :key="index_2">
                    <v-col cols="6" md="2">
                      <v-text-field
                        type="time"
												:ampm="false"
                        label="С"
                        v-model="item.start_time"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                      <v-text-field
                        type="time"
												:ampm="false"
                        label="По"
                        v-model="item.end_time"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-for="(inp, index_1) in item.price_list"
                        :key="index_1"
                        v-model="inp.price"
                        type="number"
                        :label="inp.name"
                        @keydown="preventSigns"
                        min="0"
                        style="width: 48%; display: inline-block; margin-left: 2%"
                      >
                        <template slot="append">
                          <v-icon
                            color="grey"
                            style="cursor: pointer"
                            @click="editPrice(inp.name, index_1, index_2, key)"
                            small
                            >mdi-pencil</v-icon
                          >
                          <v-icon
                            color="grey"
                            style="cursor: pointer"
                            @click="
                              openDeletePrice(inp.name, index_1, index_2, key)
                            "
                            small
                            >mdi-delete</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-row class="px-3 mt-3" v-if="!tariff.id">
                        <v-spacer />
                        <v-btn
                          @click="(addInpCheck = true), (newField = '')"
                          color="primary"
                          ><v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                          @click="deleteTime(item_1, index_2)"
                          color="error"
                          class="ml-3"
                          >Удалить время
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-btn @click="addTime(key)" v-if="isEditing"
                    >Добавить время</v-btn
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>

          <v-divider dark></v-divider>

          <v-row>
            <v-col cols="3"> </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="3">
              <v-btn
                style="width: 100%"
                @click="saveResult"
                class="mt-4"
                color="primary"
                :disabled="disabledBtn"
                >Сохранить</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addInpCheck" persistent width="580px">
      <v-card>
        <v-card-title>
          <span class="text-h3">Добавление нового поля</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Название нового поля"
                  v-model="newField"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row>
          <v-col cols="1" md="3"></v-col>
          <v-col cols="10" md="6">
            <v-btn
              color="primary"
              @click="
                addInpCheck = false;
                newField = '';
              "
            >
              Закрыть
            </v-btn>
            <v-btn color="primary" @click="addField"> Сохранить </v-btn>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deletePriceCheck" persistent width="550px">
      <v-card>
        <v-card-title>
          <span class="text-h3">Удалить поле {{ priceName }}</span>
        </v-card-title>
        <v-row>
          <v-col cols="3"></v-col>
          <v-col cols="7">
            <v-btn
              color="primary"
              @click="
                deletePriceCheck = false;
                newField = '';
              "
            >
              Закрыть
            </v-btn>
            <v-btn color="primary" @click="deletePrice"> Удалить </v-btn>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editPriceCheck" persistent width="580px">
      <v-card>
        <v-card-title>
          <span class="text-h3">Изменить название поля {{ priceName }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Название нового поля"
                  v-model="newField"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row>
          <v-col cols="1" md="3"></v-col>
          <v-col cols="10" md="6">
            <v-btn
              color="primary"
              @click="
                editPriceCheck = false;
                newField = '';
              "
            >
              Закрыть
            </v-btn>
            <v-btn color="primary" @click="editPrice"> Изменить </v-btn>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import toast from "../../../plugins/toast";
import { LeisureTariffsApiUrls } from "@/services/leisureRequests/tariffs.api.js";
import { LeisureCategoriesApiUrls } from "@/services/leisureRequests/categories.api.js";

export default {
  data: () => ({
    disabledBtn: false,
    arr: [],
    periodType: "",
    periodName: "",
    isEditing: false,
    tariff: {},
    catregoryList: [],
    loading: false,
    periodLabel: "Выберите период",
    selectItems: [],

    keyTime: null,
    indextTime: "",
    indexPrice: "",
    editPriceCheck: false,
    priceName: "",
    deletePriceCheck: false,
    editId: "",
    editingCategoryName: "",
    addInpCheck: false,
    newField: "",
    arrayOfInputs: [],
    newResult: {
      price_type: "",
      deleted: false,
      category: "",
      time_list: [
        {
          price_list: [
            {
              name: "Взрослый",
              price: ""
            }
          ]
        }
      ],
      enum: ""
    },
    items: [],
    periodList: [
      {
        text: "По дням",
        value: "Дни недели"
      },
      {
        text: "По месяцам",
        value: "Месяцы"
      }
    ],
    headers: [
      { text: "№", value: "id", class: "table-title" },
      { text: "Название", value: "category_name", class: "table-title" },
      { text: "Действия", value: "actions", class: "table-title" }
    ],
    dialog: false,
    editingItem: {},
    footerProps: {
      "items-per-page-options": [
        {
          text: "10",
          value: 10
        },
        {
          text: "20",
          value: 20
        },
        {
          text: "30",
          value: 30
        },
        {
          text: "Все",
          value: 99999999999
        }
      ]
    },
    options: {},
    firstWatcherTrigger: false,
    convertedEnum: {
      jan: "Январь",
      feb: "Ферваль",
      mar: "Март",
      apr: "Апрель",
      may: "Май",
      jun: "Июнь",
      jul: "Июль",
      aug: "Август",
      sep: "Сентябрь",
      okt: "Октябрь",
      nov: "Ноябрь",
      dec: "Декабрь",
      mon: "Понедельник",
      tue: "Вторник",
      wed: "Среда",
      thu: "Четверг",
      fri: "Пятница",
      sat: "Суббота",
      sun: "Воскресенье"
    }
  }),
  watch: {
    dialog() {
      if (!this.dialog) {
        this.getList();
        this.loadRow();
      }
    },
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    }
  },
  created() {
    this.getList();
    this.loadRow();
  },
  methods: {
    addTime(key) {
      let new_time = this.newResult.time_list[key][0]
        ? this.newResult.time_list[key][0]
        : this.newResult.time_list[Object.keys(this.newResult.time_list)[0]][0];
      let new_prices = [];
      new_time.price_list.forEach(e => {
        new_prices.push({
          enum: e.enum,
          name: e.name,
          price: 0
        });
      });
      let newer_time = {
        enum: new_time.enum,
        tariff: new_time.tariff,
        price_list: [...new_prices]
      };
      this.newResult.time_list[key].push(newer_time);
    },
    deleteTime(item, index_1) {
      item.splice(index_1, 1);
    },
    setPeriod(e) {
      this.periodLabel = e;
      if (e == "Дни недели") {
        this.periodName = "день";
        this.selectItems = [
          {
            text: "Понедельник",
            value: "mon"
          },
          {
            text: "Вторник",
            value: "tue"
          },
          {
            text: "Среда",
            value: "wed"
          },
          {
            text: "Четверг",
            value: "thu"
          },
          {
            text: "Пятница",
            value: "fri"
          },
          {
            text: "Суббота",
            value: "sat"
          },
          {
            text: "Воскресенье",
            value: "sun"
          }
        ];
        this.newResult.price_type = "weekdays";
      } else {
        this.periodName = "месяц";
        this.selectItems = [
          {
            text: "Январь",
            value: "jan"
          },
          {
            text: "Февраль",
            value: "feb"
          },
          {
            text: "Март",
            value: "mar"
          },
          {
            text: "Апрель",
            value: "apr"
          },
          {
            text: "Май",
            value: "may"
          },
          {
            text: "Июнь",
            value: "jun"
          },
          {
            text: "Июль",
            value: "jul"
          },
          {
            text: "Август",
            value: "aug"
          },
          {
            text: "Сентябрь",
            value: "sep"
          },
          {
            text: "Октябрь",
            value: "okt"
          },
          {
            text: "Ноябрь",
            value: "nov"
          },
          {
            text: "Декабрь",
            value: "dec"
          }
        ];
        this.newResult.price_type = "period";
      }
    },
    preventSigns(e) {
      if (e.key == "-" || e.key == "+") {
        e.preventDefault();
      }
    },
    openTariff() {
      this.arrayOfInputs = [];
      this.tariff = {};
      this.editingItem = [];
      this.isEditing = false;
      this.dialog = true;

      this.newResult = {
        price_type: "",
        deleted: false,
        category: 1,
        time_list: {
          day: [{ price_list: [] }]
        }
      };

      window.axios.get;
    },
    async loadRow() {
      this.loading = true;
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10
      };
      try {
        let res = await LeisureTariffsApiUrls.getTariffs(params);
        this.items = res.results;
      } finally {
        this.loading = false;
      }
    },
    async getList() {
      this.loading = true;

      let params = {
        "page[number]": 1,
        "page[size]": 10000,
        is_active: true
      };
      try {
        let resp = await LeisureCategoriesApiUrls.getCategories(params);
        this.catregoryList = resp;
      } finally {
        this.loading = false;
      }
    },
    async edit(item) {
      let res = await LeisureTariffsApiUrls.getTariff(item.id);
      this.newResult.category = res.category;
      this.newResult.price_type = res.price_type;
      this.newResult = res;
      this.newResult.time_list = res.time_list_v2;

      if (this.newResult && this.newResult.time_list) {
        for (const [key, value] of Object.entries(this.newResult.time_list)) {
          if (value.price_list) {
            for (
              let i = 0;
              i < this.newResult.time_list[0].price_list.length;
              i++
            ) {
              // добавить в array(для новых fields)
              this.arrayOfInputs.push({
                name: this.newResult.time_list[0].price_list[i].name,
                price: ""
              });
            }
          }
        }
      }
      let arr = Object.keys(this.newResult.time_list);

      let first;
      if (this.newResult.time_list)
        first = Object.keys(this.newResult.time_list)[0];

      this.arr = JSON.parse(JSON.stringify(this.newResult.time_list[first])); // нужен для того чтобы если добавить новый день то этот this.arr добавляется

      let hour_period;
      if (
        this.newResult.time_list[Object.keys(this.newResult.time_list)[0]]
          .length > 0
      ) {
        hour_period =
          parseInt(
            this.newResult.time_list[
              Object.keys(this.newResult.time_list)[0]
            ][0].end_time
          ) -
          parseInt(
            this.newResult.time_list[
              Object.keys(this.newResult.time_list)[0]
            ][0].start_time
          );
      }
      this.newResult.enum = arr;
      this.newResult.hour_period = `${hour_period}`;

      if (this.newResult.price_type == "weekdays") {
        this.periodType = "Дни недели";
        this.setPeriod("Дни недели");
      } else {
        this.periodType = "По месяцам";
        this.setPeriod("Месяцы");
      }
      this.editingCategoryName = item.category_name;
      this.isEditing = true;
      this.dialog = true;
      this.editId = item.id;
    },
    async deleteTariff(item) {
      await LeisureTariffsApiUrls.deleteTariff(item.id);
      toast.success("Успешно удален!");
      this.loadRow();
    },
    addField() {
      for (const [key, value] of Object.entries(this.newResult.time_list)) {
        if (value) {
          value.forEach(e => {
            e.price_list.push({
              name: this.newField,
              price: ""
            });
          });
        }
      }
      this.arrayOfInputs.push({
        name: this.newField,
        price: ""
      });
      this.newField = "";
      this.addInpCheck = false;
    },
    async saveResult(item) {
      if (!this.newResult.category) {
        toast.error("Выберите категорию!");
        return;
      }
      let first_key = Object.keys(this.newResult.time_list)[0];

      if (
        !this.newResult.time_list[first_key][0].start_time ||
        !this.newResult.time_list[first_key][0].end_time
      ) {
        toast.error("Выберите время!");
        return;
      }
      if (this.newResult.time_list[first_key][0].price_list.length == 0) {
        toast.error("Создайте поля!");
        return;
      }
      if (!this.newResult.price_type) {
        toast.error("Выберите период!");
        return;
      }
      if (!this.newResult.hour_period) {
        toast.error("Напишите период активности!");
        return;
      }
      let workd_dates = [];
      if (this.newResult.enum) {
        this.newResult.enum.forEach(e => {
          workd_dates.push({
            enum: e
          });
        });
      }
      if (workd_dates.length == 0) {
        toast.error("Выберите дни или месяца!");
        return;
      }
      let body = {
        category: this.newResult.category,
        workd_dates: workd_dates,
        start_time: this.newResult.time_list[first_key][0].start_time,
        end_time: this.newResult.time_list[first_key][0].end_time,
        prices: this.newResult.time_list[first_key][0].price_list,
        hour_period: this.newResult.hour_period,
        price_type: this.newResult.price_type
      };
      if (!this.isEditing) {
        this.disabledBtn = true;
        try {
          await LeisureTariffsApiUrls.createTariff(body);
          toast.success("Успешно создано!");
          this.loadRow();
          this.dialog = false;
          this.periodType = "";
        } finally {
          this.disabledBtn = false;
        }
      } else {
        let time_list = Object.values(this.newResult.time_list).reduce(
          (prev, cur) => {
            return prev.concat(cur);
          },
          []
        );
        let body_2 = {
          category: this.newResult.category,
          price_type: this.newResult.price_type,
          time_list: time_list
        };
        this.disabledBtn = true;
        try {
          await LeisureTariffsApiUrls.setTariff(this.editId, body_2);
          toast.success("Успешно изменилось!");
          this.loadRow();
          this.dialog = false;
          this.periodType = "";
        } finally {
          this.disabledBtn = false;
        }
      }
    },
    handleChange() {
      if (!this.isEditing) return;
      // добавить
      let arr = [];

      this.newResult.enum.forEach(e => {
        if (!this.newResult.time_list[e]) {
          arr = this.arr;
          arr.forEach(j => {
            j.enum = e;
          });
          this.$set(this.newResult.time_list, e, [...arr]);
        }
      });

      // удалить
      Object.keys(this.newResult.time_list).forEach(item => {
        if (!this.newResult.enum.includes(item)) {
          delete this.newResult.time_list[item];
        }
      });

      let obj = { ...this.newResult };
      this.newResult = {};
      this.newResult = { ...obj };
    },
    editPrice(priceName, indexPrice, indexTime, keyTime) {
      if (typeof priceName == "string") this.newField = priceName;
      if (keyTime) this.keyTime = keyTime;
      if (typeof indexPrice == "number") this.indexPrice = indexPrice;
      if (typeof indexTime == "number") this.indexTime = indexTime;

      if (this.editPriceCheck) {
        if (!this.newField.length > 0) {
          toast.error("Напишите название нового поля");
        } else {
          this.newResult.time_list[this.keyTime][this.indexTime].price_list[
            this.indexPrice
          ].name = this.newField;
          toast.success("Изменено поле");
          this.editPriceCheck = false;
        }
      } else {
        this.editPriceCheck = true;
      }
    },
    openDeletePrice(priceName, indexPrice, indexTime, keyTime) {
      this.priceName = priceName;
      this.keyTime = keyTime;
      this.indexPrice = indexPrice;
      this.indexTime = indexTime;
      this.deletePriceCheck = true;
    },
    deletePrice() {
      this.newResult.time_list[this.keyTime][this.indexTime].price_list.splice(
        this.indexPrice,
        1
      );
      this.deletePriceCheck = false;
      toast.success("Удалено поле " + this.priceName);
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
