const axios = window.axios
const url = '/api/v2/categories/leisure/'
const urlImages = '/api/v2/categories/leisure-images/'
const urlMainImage = '/api/v2/categories/leisure-images/main/'

const urlRoom = '/api/v2/categories/rooms/'

export const LeisureCategoriesApiUrls = {
    // categories категории в настройках зона отдыха
    async getCategories(params) {
        return (await axios.get(url, { params: params })).data.results;
    },
    async createCategory(body) {
        return (await axios.post(url, body)).data;
    },
    async getCategory(id) {
        return (await axios.get(`${url}${id}/`)).data;
    },
    async setCategory(id, body) {
        return (await axios.put(`${url}${id}/`, body)).data;
    },
    async deleteCategory(id) {
        return (await axios.delete(`${url}${id}/`)).data;
    },

    // картинки категории
    async createCategoryImage(formdata) {
        return (await axios.post(urlImages, formdata)).data;
    },
    async setCategoryImage(id, formdata) {
        return (await axios.patch(`${urlImages}${id}/`, formdata)).data;
    },
    async deleteCategoryImage(id) {
        return (await axios.delete(`${urlImages}${id}/`)).data;
    },

    // главная картинка категории
    async setCategoryMainImage(id, formdata) {
        return (await axios.patch(`${urlMainImage}${id}/`, formdata)).data;
    },




    // rooms номера в настройках зона отдыха
    async getRooms(params){
        return (await axios.get(`${urlRoom}`, { params: params })).data;
    },
    async getSelectedRooms(id){
        return (await axios.get(`${url}category-rooms/${id}/`)).data;
    },
    async createRoom(body){
        return (await axios.post(`${urlRoom}`, body)).data;
    },
    async getRoom(id){
        return (await axios.get(`${urlRoom}${id}/`)).data;
    },
    async setRoom(id, body){
        return (await axios.put(`${urlRoom}${id}/`, body)).data;
    },
    async deleteRoom(id){
        return (await axios.delete(`${urlRoom}${id}/`)).data;
    },
};
  